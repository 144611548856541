const Ankerslogen = ({ isMobile, ...props }: { isMobile: boolean }) => {
  return (
    <svg
      viewBox="0 0 481 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M114.36 34.79L123.98 50.11L132.85 50.12L119.19 28.39L114.36 34.79Z"
        fill="#00A9E0"
      />
      <path
        d="M196.18 33.24C200.92 29.61 203.01 25.82 202.68 20.95C202.19 13.85 196.45 9.08997 189.91 9.08997H171.15V50.12H178.61V33.24H187.42L197.96 50.12H206.76L196.18 33.24ZM195.45 20.98C195.45 24.12 193.24 25.97 189.87 25.97H178.61V16.34H189.87C194.12 16.34 195.45 19.27 195.45 20.97"
        fill="#00A9E0"
      />
      <path
        d="M130.14 9.08997H120.67L101.17 35.02V9.08997H93.6799V46.49C93.6799 47.87 94.4799 49.57 96.6199 50.06C97.4699 50.26 99.1699 50.05 100.2 48.71C100.33 48.55 130.15 9.09997 130.15 9.09997"
        fill="#00A9E0"
      />
      <path d="M146.74 25.97H161.47V33.24H146.74V25.97Z" fill="#00A9E0" />
      <path
        d="M165.26 9.08997H136.03V46.47C136.03 48.48 137.66 50.12 139.68 50.12H165.27V42.87H143.51V16.36H165.27V9.08997H165.26Z"
        fill="#00A9E0"
      />
      <path
        d="M78.6999 9.08994V35.0199L59.1899 9.08994H49.6699V50.1H57.1499V18.91C65.9899 30.5999 79.5899 48.5799 79.6699 48.6899C80.6899 50.0299 82.3899 50.2399 83.2499 50.0399C85.3899 49.5499 86.1899 47.8499 86.1899 46.4699V9.06995H78.6999V9.08994Z"
        fill="#00A9E0"
      />
      <path
        d="M19.18 11.3L0 50.12H8.36L22.81 20.98L37.25 50.12H45.53L26.5 11.43C25.79 9.99997 24.34 9.08997 22.74 9.08997C21.23 9.08997 19.85 9.94997 19.19 11.3"
        fill="#00A9E0"
      />
      <path
        d="M29.57 44.32H24.63C24.63 44.32 24.55 44.28 24.55 44.23L25.51 33.69C25.51 33.61 25.41 33.57 25.37 33.63L14.73 47.55C14.69 47.6 14.73 47.68 14.79 47.68H19.73C19.73 47.68 19.81 47.72 19.81 47.77L18.85 58.31C18.85 58.39 18.95 58.43 18.99 58.37L29.63 44.45C29.67 44.4 29.63 44.32 29.57 44.32Z"
        fill="#00A9E0"
      />
      <path d="M227.58 9.09998H229.12V50.03H227.58V9.09998Z" fill="#00A9E0" />
      <path
        d="M336.32 24.8801C334.13 24.8801 333.25 23.8301 333.25 21.6701V9.16006C333.25 9.02006 333.34 8.93005 333.48 8.93005H335.62C335.76 8.93005 335.85 9.02006 335.85 9.16006V21.5301C335.85 22.3001 336.15 22.5501 336.83 22.5501H337.34C337.48 22.5501 337.57 22.6401 337.57 22.7801V24.6401C337.57 24.7801 337.48 24.8701 337.34 24.8701H336.32V24.8801Z"
        fill="#00A9E0"
      />
      <path
        d="M249 34.47C249 34.33 249.09 34.24 249.23 34.24H251.3C251.51 34.24 251.63 34.31 251.7 34.47L256.05 44.22H256.14L260.42 34.47C260.49 34.31 260.61 34.24 260.81 34.24H262.86C263 34.24 263.09 34.33 263.09 34.47V49.82C263.09 49.96 263 50.05 262.86 50.05H260.84C260.7 50.05 260.61 49.96 260.61 49.82V40.14H260.52L257.17 47.58C257.08 47.79 256.94 47.88 256.73 47.88H255.38C255.17 47.88 255.03 47.79 254.94 47.58L251.59 40.14H251.5V49.82C251.5 49.96 251.41 50.05 251.27 50.05H249.25C249.11 50.05 249.02 49.96 249.02 49.82V34.47H249Z"
        fill="#00A9E0"
      />
      <path
        d="M266.14 47.22C265.88 46.43 265.77 45.68 265.77 44.45C265.77 43.22 265.89 42.47 266.14 41.68C266.77 39.73 268.49 38.59 270.77 38.59C273.05 38.59 274.75 39.73 275.38 41.68C275.64 42.47 275.75 43.22 275.75 44.45C275.75 45.68 275.63 46.43 275.38 47.22C274.75 49.17 273.03 50.31 270.77 50.31C268.51 50.31 266.77 49.17 266.14 47.22ZM272.86 46.52C273.05 45.94 273.09 45.4 273.09 44.45C273.09 43.5 273.04 42.98 272.86 42.38C272.53 41.45 271.81 40.91 270.77 40.91C269.73 40.91 268.98 41.45 268.65 42.38C268.46 42.98 268.42 43.5 268.42 44.45C268.42 45.4 268.47 45.94 268.65 46.52C268.98 47.45 269.72 47.99 270.77 47.99C271.82 47.99 272.54 47.46 272.86 46.52Z"
        fill="#00A9E0"
      />
      <path
        d="M280.92 48.99H280.9V49.83C280.9 49.97 280.81 50.06 280.67 50.06H278.53C278.39 50.06 278.3 49.97 278.3 49.83V34.48C278.3 34.34 278.39 34.25 278.53 34.25H280.67C280.81 34.25 280.9 34.34 280.9 34.48V39.92H280.92C281.46 39.15 282.39 38.59 283.9 38.59C285.81 38.59 287.2 39.5 287.78 41.29C288.06 42.15 288.18 42.94 288.18 44.45C288.18 45.96 288.06 46.75 287.78 47.61C287.2 49.4 285.8 50.31 283.9 50.31C282.39 50.31 281.46 49.75 280.92 48.98V48.99ZM285.29 46.55C285.45 46.04 285.52 45.48 285.52 44.46C285.52 43.44 285.45 42.88 285.29 42.37C284.99 41.44 284.27 40.93 283.22 40.93C282.17 40.93 281.43 41.44 281.13 42.37C280.97 42.88 280.9 43.44 280.9 44.46C280.9 45.48 280.97 46.04 281.13 46.55C281.43 47.48 282.15 47.99 283.22 47.99C284.29 47.99 284.99 47.48 285.29 46.55Z"
        fill="#00A9E0"
      />
      <path
        d="M291.02 36.73C290.88 36.73 290.79 36.64 290.79 36.5V34.48C290.79 34.34 290.88 34.25 291.02 34.25H293.16C293.3 34.25 293.39 34.34 293.39 34.48V36.5C293.39 36.64 293.3 36.73 293.16 36.73H291.02ZM291.02 50.06C290.88 50.06 290.79 49.97 290.79 49.83V39.08C290.79 38.94 290.88 38.85 291.02 38.85H293.16C293.3 38.85 293.39 38.94 293.39 39.08V49.83C293.39 49.97 293.3 50.06 293.16 50.06H291.02Z"
        fill="#00A9E0"
      />
      <path
        d="M299.45 50.2C297.26 50.2 296.38 49.15 296.38 46.99V34.48C296.38 34.34 296.47 34.25 296.61 34.25H298.75C298.89 34.25 298.98 34.34 298.98 34.48V46.85C298.98 47.62 299.28 47.87 299.96 47.87H300.47C300.61 47.87 300.7 47.96 300.7 48.1V49.96C300.7 50.1 300.61 50.19 300.47 50.19H299.45V50.2Z"
        fill="#00A9E0"
      />
      <path
        d="M302.68 47.2C302.45 46.48 302.28 45.62 302.28 44.46C302.28 43.3 302.42 42.41 302.65 41.69C303.28 39.74 305 38.6 307.23 38.6C309.46 38.6 311.21 39.79 311.84 41.69C312.1 42.48 312.21 43.25 312.21 45.06C312.21 45.2 312.12 45.29 311.95 45.29H305.04C304.95 45.29 304.9 45.34 304.9 45.43C304.9 45.76 304.97 46.03 305.06 46.29C305.43 47.38 306.32 47.99 307.57 47.99C308.82 47.99 309.64 47.52 310.2 46.92C310.32 46.8 310.43 46.78 310.55 46.87L311.92 48.08C312.04 48.17 312.04 48.29 311.94 48.41C310.99 49.53 309.4 50.32 307.33 50.32C304.96 50.32 303.28 49.16 302.65 47.2H302.68ZM309.45 42.25C309.15 41.34 308.31 40.81 307.26 40.81C306.21 40.81 305.35 41.35 305.05 42.25C304.96 42.53 304.91 42.81 304.91 43.2C304.91 43.29 304.96 43.34 305.05 43.34H309.45C309.54 43.34 309.59 43.29 309.59 43.2C309.59 42.8 309.54 42.53 309.45 42.25Z"
        fill="#00A9E0"
      />
      <path
        d="M320.08 42.15C320.08 39.64 320.17 38.85 320.5 37.87C321.31 35.29 323.31 33.99 325.99 33.99C328.53 33.99 330.43 35.29 331.34 37.39C331.39 37.51 331.34 37.62 331.22 37.67L329.24 38.62C329.1 38.69 328.98 38.62 328.94 38.5C328.43 37.38 327.54 36.45 325.99 36.45C324.44 36.45 323.59 37.26 323.15 38.54C322.94 39.19 322.87 39.82 322.87 42.15C322.87 44.48 322.94 45.1 323.15 45.76C323.59 47.04 324.48 47.85 325.99 47.85C327.5 47.85 328.43 46.92 328.94 45.8C328.99 45.68 329.1 45.61 329.24 45.68L331.22 46.63C331.34 46.68 331.38 46.79 331.34 46.91C330.43 49 328.53 50.31 325.99 50.31C323.32 50.31 321.31 49.01 320.5 46.43C320.17 45.45 320.08 44.66 320.08 42.15Z"
        fill="#00A9E0"
      />
      <path
        d="M340.73 50.06C340.59 50.06 340.5 49.97 340.5 49.83V43.36C340.5 41.92 339.78 40.92 338.38 40.92C336.98 40.92 336.24 41.92 336.24 43.36V49.83C336.24 49.97 336.15 50.06 336.01 50.06H333.87C333.73 50.06 333.64 49.97 333.64 49.83V34.48C333.64 34.34 333.73 34.25 333.87 34.25H336.01C336.15 34.25 336.24 34.34 336.24 34.48V39.9H336.26C336.75 39.2 337.73 38.6 339.21 38.6C341.61 38.6 343.09 40.37 343.09 42.76V49.83C343.09 49.97 343 50.06 342.86 50.06H340.72H340.73Z"
        fill="#00A9E0"
      />
      <path
        d="M352.64 50.06C352.5 50.06 352.41 49.97 352.41 49.83V49.02H352.39C351.86 49.79 350.81 50.32 349.23 50.32C347.18 50.32 345.46 49.25 345.46 46.9C345.46 44.55 347.18 43.34 349.95 43.34H352.23C352.32 43.34 352.37 43.29 352.37 43.2V42.67C352.37 41.39 351.77 40.81 349.88 40.81C348.62 40.81 347.86 41.16 347.32 41.58C347.2 41.67 347.06 41.65 346.99 41.53L346.13 40C346.06 39.88 346.08 39.77 346.18 39.7C347.09 39.05 348.32 38.61 350.16 38.61C353.58 38.61 354.84 39.77 354.84 42.52V49.85C354.84 49.99 354.75 50.08 354.61 50.08H352.66L352.64 50.06ZM352.36 46.29V45.43C352.36 45.34 352.31 45.29 352.22 45.29H350.36C348.68 45.29 347.94 45.76 347.94 46.8C347.94 47.73 348.61 48.2 349.87 48.2C351.48 48.2 352.36 47.57 352.36 46.29Z"
        fill="#00A9E0"
      />
      <path
        d="M358.05 50.06C357.91 50.06 357.82 49.97 357.82 49.83V39.08C357.82 38.94 357.91 38.85 358.05 38.85H360.19C360.33 38.85 360.42 38.94 360.42 39.08V40.03H360.44C360.98 39.17 361.93 38.59 363.35 38.59C364.23 38.59 365.12 38.94 365.7 39.52C365.82 39.64 365.84 39.73 365.75 39.85L364.52 41.41C364.43 41.53 364.31 41.55 364.19 41.46C363.68 41.13 363.14 40.92 362.54 40.92C361.07 40.92 360.42 41.97 360.42 43.76V49.83C360.42 49.97 360.33 50.06 360.19 50.06H358.05Z"
        fill="#00A9E0"
      />
      <path
        d="M367.3 52.83C367.21 52.71 367.21 52.62 367.32 52.5L368.69 51.13C368.81 51.01 368.92 51.01 369.04 51.13C369.74 51.8 370.55 52.18 371.48 52.18C373.2 52.18 373.99 51.25 373.99 49.51V48.46H373.97C373.46 49.25 372.5 49.76 371.06 49.76C369.18 49.76 367.83 48.81 367.25 47.04C366.97 46.18 366.88 45.41 366.88 44.18C366.88 42.95 366.97 42.2 367.25 41.34C367.83 39.55 369.18 38.6 371.06 38.6C372.5 38.6 373.46 39.11 373.97 39.93H373.99V39.09C373.99 38.95 374.08 38.86 374.22 38.86H376.36C376.5 38.86 376.59 38.95 376.59 39.09V49.37C376.59 52.58 374.8 54.51 371.36 54.51C369.8 54.51 368.15 53.86 367.29 52.84L367.3 52.83ZM373.74 46.01C373.93 45.5 374 44.94 374 44.17C374 43.4 373.93 42.87 373.74 42.33C373.44 41.42 372.81 40.91 371.74 40.91C370.67 40.91 370.07 41.42 369.76 42.33C369.57 42.86 369.5 43.42 369.5 44.17C369.5 44.92 369.57 45.5 369.76 46.01C370.06 46.94 370.69 47.45 371.74 47.45C372.79 47.45 373.44 46.94 373.74 46.01Z"
        fill="#00A9E0"
      />
      <path
        d="M379.89 36.73C379.75 36.73 379.66 36.64 379.66 36.5V34.48C379.66 34.34 379.75 34.25 379.89 34.25H382.03C382.17 34.25 382.26 34.34 382.26 34.48V36.5C382.26 36.64 382.17 36.73 382.03 36.73H379.89ZM379.89 50.06C379.75 50.06 379.66 49.97 379.66 49.83V39.08C379.66 38.94 379.75 38.85 379.89 38.85H382.03C382.17 38.85 382.26 38.94 382.26 39.08V49.83C382.26 49.97 382.17 50.06 382.03 50.06H379.89Z"
        fill="#00A9E0"
      />
      <path
        d="M392.37 50.06C392.23 50.06 392.14 49.97 392.14 49.83V43.36C392.14 41.92 391.42 40.92 390.02 40.92C388.62 40.92 387.88 41.92 387.88 43.36V49.83C387.88 49.97 387.79 50.06 387.65 50.06H385.51C385.37 50.06 385.28 49.97 385.28 49.83V39.08C385.28 38.94 385.37 38.85 385.51 38.85H387.65C387.79 38.85 387.88 38.94 387.88 39.08V39.89H387.9C388.39 39.19 389.37 38.59 390.85 38.59C393.25 38.59 394.73 40.36 394.73 42.75V49.82C394.73 49.96 394.64 50.05 394.5 50.05H392.36L392.37 50.06Z"
        fill="#00A9E0"
      />
      <path
        d="M397.61 52.83C397.52 52.71 397.52 52.62 397.63 52.5L399 51.13C399.12 51.01 399.23 51.01 399.35 51.13C400.05 51.8 400.86 52.18 401.79 52.18C403.51 52.18 404.3 51.25 404.3 49.51V48.46H404.28C403.77 49.25 402.81 49.76 401.37 49.76C399.49 49.76 398.14 48.81 397.56 47.04C397.28 46.18 397.19 45.41 397.19 44.18C397.19 42.95 397.28 42.2 397.56 41.34C398.14 39.55 399.49 38.6 401.37 38.6C402.81 38.6 403.77 39.11 404.28 39.93H404.3V39.09C404.3 38.95 404.39 38.86 404.53 38.86H406.67C406.81 38.86 406.9 38.95 406.9 39.09V49.37C406.9 52.58 405.11 54.51 401.67 54.51C400.11 54.51 398.46 53.86 397.6 52.84L397.61 52.83ZM404.05 46.01C404.24 45.5 404.31 44.94 404.31 44.17C404.31 43.4 404.24 42.87 404.05 42.33C403.75 41.42 403.12 40.91 402.05 40.91C400.98 40.91 400.38 41.42 400.07 42.33C399.88 42.86 399.81 43.42 399.81 44.17C399.81 44.92 399.88 45.5 400.07 46.01C400.37 46.94 401 47.45 402.05 47.45C403.1 47.45 403.75 46.94 404.05 46.01Z"
        fill="#00A9E0"
      />
      <path
        d="M415.84 34.47C415.84 34.33 415.93 34.24 416.07 34.24H421.96C425.31 34.24 426.89 35.91 426.89 38.45C426.89 40.24 426.05 41.26 425.05 41.8V41.85C426.03 42.22 427.17 43.48 427.17 45.41C427.17 48.55 425.19 50.06 421.73 50.06H416.08C415.94 50.06 415.85 49.97 415.85 49.83V34.48L415.84 34.47ZM421.89 40.82C423.29 40.82 424.15 39.98 424.15 38.75C424.15 37.52 423.29 36.66 421.89 36.66H418.73C418.64 36.66 418.59 36.71 418.59 36.8V40.68C418.59 40.77 418.64 40.82 418.73 40.82H421.89ZM418.73 47.64H422.03C423.57 47.64 424.43 46.76 424.43 45.43C424.43 44.1 423.57 43.24 422.03 43.24H418.73C418.64 43.24 418.59 43.29 418.59 43.38V47.5C418.59 47.59 418.64 47.64 418.73 47.64Z"
        fill="#00A9E0"
      />
      <path
        d="M429.98 50.06C429.84 50.06 429.75 49.97 429.75 49.83V39.08C429.75 38.94 429.84 38.85 429.98 38.85H432.12C432.26 38.85 432.35 38.94 432.35 39.08V40.03H432.37C432.91 39.17 433.86 38.59 435.28 38.59C436.16 38.59 437.05 38.94 437.63 39.52C437.75 39.64 437.77 39.73 437.68 39.85L436.45 41.41C436.36 41.53 436.24 41.55 436.12 41.46C435.61 41.13 435.07 40.92 434.47 40.92C433 40.92 432.35 41.97 432.35 43.76V49.83C432.35 49.97 432.26 50.06 432.12 50.06H429.98Z"
        fill="#00A9E0"
      />
      <path
        d="M446.21 50.06C446.07 50.06 445.98 49.97 445.98 49.83V49.02H445.96C445.43 49.79 444.38 50.32 442.8 50.32C440.75 50.32 439.03 49.25 439.03 46.9C439.03 44.55 440.75 43.34 443.52 43.34H445.8C445.89 43.34 445.94 43.29 445.94 43.2V42.67C445.94 41.39 445.34 40.81 443.45 40.81C442.19 40.81 441.43 41.16 440.89 41.58C440.77 41.67 440.63 41.65 440.56 41.53L439.7 40C439.63 39.88 439.65 39.77 439.75 39.7C440.66 39.05 441.89 38.61 443.73 38.61C447.15 38.61 448.41 39.77 448.41 42.52V49.85C448.41 49.99 448.32 50.08 448.18 50.08H446.23L446.21 50.06ZM445.93 46.29V45.43C445.93 45.34 445.88 45.29 445.79 45.29H443.93C442.25 45.29 441.51 45.76 441.51 46.8C441.51 47.73 442.18 48.2 443.44 48.2C445.05 48.2 445.93 47.57 445.93 46.29Z"
        fill="#00A9E0"
      />
      <path
        d="M458.48 50.06C458.34 50.06 458.25 49.97 458.25 49.83V43.36C458.25 41.92 457.53 40.92 456.13 40.92C454.73 40.92 453.99 41.92 453.99 43.36V49.83C453.99 49.97 453.9 50.06 453.76 50.06H451.62C451.48 50.06 451.39 49.97 451.39 49.83V39.08C451.39 38.94 451.48 38.85 451.62 38.85H453.76C453.9 38.85 453.99 38.94 453.99 39.08V39.89H454.01C454.5 39.19 455.48 38.59 456.96 38.59C459.36 38.59 460.84 40.36 460.84 42.75V49.82C460.84 49.96 460.75 50.05 460.61 50.05H458.47L458.48 50.06Z"
        fill="#00A9E0"
      />
      <path
        d="M470.86 50.06C470.72 50.06 470.63 49.97 470.63 49.83V48.99H470.61C470.08 49.76 469.14 50.32 467.63 50.32C465.72 50.32 464.33 49.41 463.75 47.62C463.47 46.76 463.35 45.97 463.35 44.46C463.35 42.95 463.47 42.16 463.75 41.3C464.33 39.51 465.73 38.6 467.63 38.6C469.14 38.6 470.07 39.16 470.61 39.93H470.63V34.49C470.63 34.35 470.72 34.26 470.86 34.26H473C473.14 34.26 473.23 34.35 473.23 34.49V49.84C473.23 49.98 473.14 50.07 473 50.07H470.86V50.06ZM470.4 46.55C470.56 46.04 470.63 45.48 470.63 44.46C470.63 43.44 470.56 42.88 470.4 42.37C470.1 41.44 469.38 40.93 468.31 40.93C467.24 40.93 466.54 41.44 466.24 42.37C466.08 42.88 466.01 43.44 466.01 44.46C466.01 45.48 466.08 46.04 466.24 46.55C466.54 47.48 467.26 47.99 468.31 47.99C469.36 47.99 470.1 47.48 470.4 46.55Z"
        fill="#00A9E0"
      />
      <path
        d="M477.63 38.77L477.65 37.45L476.51 38.13C476.43 38.18 476.33 38.15 476.28 38.06L475.94 37.44C475.89 37.36 475.92 37.26 476.01 37.21L477.15 36.57L476.01 35.93C475.93 35.88 475.9 35.78 475.94 35.7L476.28 35.08C476.33 35 476.43 34.97 476.51 35.01L477.65 35.69L477.63 34.37C477.63 34.27 477.7 34.21 477.79 34.21H478.49C478.59 34.21 478.65 34.28 478.65 34.37L478.63 35.69L479.77 35.01C479.85 34.96 479.95 34.99 480 35.08L480.34 35.7C480.39 35.78 480.36 35.88 480.28 35.93L479.14 36.57L480.28 37.21C480.36 37.26 480.39 37.36 480.34 37.44L480 38.06C479.95 38.14 479.85 38.17 479.77 38.13L478.63 37.45L478.65 38.77C478.65 38.87 478.59 38.93 478.49 38.93H477.79C477.69 38.93 477.63 38.86 477.63 38.77Z"
        fill="#00A9E0"
      />
      <path
        d="M253.74 24.9C253.6 24.9 253.51 24.81 253.51 24.67V11.69C253.51 11.6 253.46 11.55 253.37 11.55H249.23C249.09 11.55 249 11.46 249 11.32V9.31997C249 9.17997 249.09 9.08997 249.23 9.08997H260.53C260.67 9.08997 260.76 9.17997 260.76 9.31997V11.32C260.76 11.46 260.67 11.55 260.53 11.55H256.39C256.3 11.55 256.25 11.6 256.25 11.69V24.67C256.25 24.81 256.16 24.9 256.02 24.9H253.74Z"
        fill="#00A9E0"
      />
      <path
        d="M269.99 24.9C269.85 24.9 269.76 24.81 269.76 24.67V18.2C269.76 16.76 269.04 15.76 267.64 15.76C266.24 15.76 265.5 16.76 265.5 18.2V24.67C265.5 24.81 265.41 24.9 265.27 24.9H263.13C262.99 24.9 262.9 24.81 262.9 24.67V9.31997C262.9 9.17997 262.99 9.08997 263.13 9.08997H265.27C265.41 9.08997 265.5 9.17997 265.5 9.31997V14.74H265.52C266.01 14.04 266.99 13.44 268.47 13.44C270.87 13.44 272.35 15.21 272.35 17.6V24.67C272.35 24.81 272.26 24.9 272.12 24.9H269.98H269.99Z"
        fill="#00A9E0"
      />
      <path
        d="M275.18 22.0399C274.95 21.3199 274.78 20.4599 274.78 19.2999C274.78 18.1399 274.92 17.2499 275.15 16.5299C275.78 14.5799 277.5 13.4399 279.73 13.4399C281.96 13.4399 283.71 14.6299 284.34 16.5299C284.6 17.3199 284.71 18.0899 284.71 19.8999C284.71 20.0399 284.62 20.1299 284.45 20.1299H277.54C277.45 20.1299 277.4 20.1799 277.4 20.2699C277.4 20.5999 277.47 20.8699 277.56 21.1299C277.93 22.2199 278.82 22.8299 280.07 22.8299C281.32 22.8299 282.14 22.3599 282.7 21.7599C282.82 21.6399 282.93 21.6199 283.05 21.7099L284.42 22.9199C284.54 23.0099 284.54 23.1299 284.44 23.2499C283.49 24.3699 281.9 25.1599 279.83 25.1599C277.46 25.1599 275.78 23.9999 275.15 22.0399H275.18ZM281.95 17.0899C281.65 16.1799 280.81 15.6499 279.76 15.6499C278.71 15.6499 277.85 16.1899 277.55 17.0899C277.46 17.3699 277.41 17.6499 277.41 18.0399C277.41 18.1299 277.46 18.1799 277.55 18.1799H281.95C282.04 18.1799 282.09 18.1299 282.09 18.0399C282.09 17.6399 282.04 17.3699 281.95 17.0899Z"
        fill="#00A9E0"
      />
      <path
        d="M304.26 24.9C304.12 24.9 304.03 24.81 303.98 24.67L300.96 13.85H300.91L297.86 24.67C297.81 24.81 297.72 24.9 297.58 24.9H295.84C295.7 24.9 295.61 24.81 295.56 24.67L291.68 9.31997C291.66 9.17997 291.7 9.08997 291.87 9.08997H294.06C294.22 9.08997 294.32 9.17997 294.34 9.31997L296.85 19.97H296.9L299.85 9.31997C299.9 9.17997 299.99 9.08997 300.13 9.08997H301.76C301.92 9.08997 301.99 9.17997 302.04 9.31997L305.06 19.97H305.11L307.55 9.31997C307.57 9.15997 307.69 9.08997 307.83 9.08997H309.99C310.15 9.08997 310.2 9.17997 310.18 9.31997L306.32 24.67C306.3 24.81 306.18 24.9 306.04 24.9H304.27H304.26Z"
        fill="#00A9E0"
      />
      <path
        d="M311.27 22.0601C311.01 21.2701 310.9 20.5201 310.9 19.2901C310.9 18.0601 311.02 17.3101 311.27 16.5201C311.9 14.5701 313.62 13.4301 315.9 13.4301C318.18 13.4301 319.88 14.5701 320.51 16.5201C320.77 17.3101 320.88 18.0601 320.88 19.2901C320.88 20.5201 320.76 21.2701 320.51 22.0601C319.88 24.0101 318.16 25.1501 315.9 25.1501C313.64 25.1501 311.9 24.0101 311.27 22.0601ZM317.99 21.3601C318.18 20.7801 318.22 20.2401 318.22 19.2901C318.22 18.3401 318.17 17.8201 317.99 17.2201C317.66 16.2901 316.94 15.7501 315.9 15.7501C314.86 15.7501 314.11 16.2901 313.78 17.2201C313.59 17.8201 313.55 18.3401 313.55 19.2901C313.55 20.2401 313.6 20.7801 313.78 21.3601C314.11 22.2901 314.85 22.8301 315.9 22.8301C316.95 22.8301 317.67 22.3001 317.99 21.3601Z"
        fill="#00A9E0"
      />
      <path
        d="M323.66 24.9001C323.52 24.9001 323.43 24.8101 323.43 24.6701V13.9201C323.43 13.7801 323.52 13.6901 323.66 13.6901H325.8C325.94 13.6901 326.03 13.7801 326.03 13.9201V14.8701H326.05C326.59 14.0101 327.54 13.4301 328.96 13.4301C329.84 13.4301 330.73 13.7801 331.31 14.3601C331.43 14.4801 331.45 14.5701 331.36 14.6901L330.13 16.2501C330.04 16.3701 329.92 16.3901 329.8 16.3001C329.29 15.9701 328.75 15.7601 328.15 15.7601C326.68 15.7601 326.03 16.8101 326.03 18.6001V24.6701C326.03 24.8101 325.94 24.9001 325.8 24.9001H323.66Z"
        fill="#00A9E0"
      />
      <path
        d="M346.74 24.9C346.6 24.9 346.51 24.81 346.51 24.67V23.83H346.49C345.96 24.6 345.02 25.16 343.51 25.16C341.6 25.16 340.21 24.25 339.63 22.46C339.35 21.6 339.23 20.81 339.23 19.3C339.23 17.79 339.35 17 339.63 16.14C340.21 14.35 341.61 13.44 343.51 13.44C345.02 13.44 345.95 14 346.49 14.77H346.51V9.32998C346.51 9.18998 346.6 9.09998 346.74 9.09998H348.88C349.02 9.09998 349.11 9.18998 349.11 9.32998V24.68C349.11 24.82 349.02 24.91 348.88 24.91H346.74V24.9ZM346.28 21.39C346.44 20.88 346.51 20.32 346.51 19.3C346.51 18.28 346.44 17.72 346.28 17.21C345.98 16.28 345.26 15.77 344.19 15.77C343.12 15.77 342.42 16.28 342.12 17.21C341.96 17.72 341.89 18.28 341.89 19.3C341.89 20.32 341.96 20.88 342.12 21.39C342.42 22.32 343.14 22.83 344.19 22.83C345.24 22.83 345.98 22.32 346.28 21.39Z"
        fill="#00A9E0"
      />
      <path
        d="M351.76 13.36V9.30995C351.76 9.16995 351.85 9.07996 351.99 9.07996H353.87C354.01 9.07996 354.1 9.16995 354.1 9.30995V11.5C354.1 11.73 354.05 11.85 353.91 11.99L352.33 13.43C352.21 13.55 352.1 13.59 351.98 13.59C351.84 13.59 351.75 13.5 351.75 13.36H351.76Z"
        fill="#00A9E0"
      />
      <path
        d="M354.97 23.39C354.88 23.3 354.88 23.16 354.97 23.06L356.3 21.66C356.39 21.57 356.53 21.57 356.63 21.66C357.44 22.4 358.75 22.94 359.91 22.94C361.28 22.94 361.98 22.38 361.98 21.61C361.98 20.94 361.58 20.52 360.12 20.38L358.91 20.26C356.63 20.03 355.47 18.86 355.47 16.96C355.47 14.8 357.03 13.42 359.82 13.42C361.56 13.42 363.03 14 364.03 14.79C364.15 14.88 364.15 15.02 364.08 15.12L362.94 16.49C362.85 16.61 362.73 16.61 362.61 16.54C361.91 16.05 360.8 15.61 359.68 15.61C358.56 15.61 357.98 16.1 357.98 16.77C357.98 17.44 358.38 17.82 359.82 17.96L361.03 18.08C363.38 18.31 364.5 19.52 364.5 21.36C364.5 23.59 362.87 25.15 359.8 25.15C357.5 25.15 355.92 24.24 354.99 23.38L354.97 23.39Z"
        fill="#00A9E0"
      />
      <path
        d="M421.37 24.5401L422.23 19.5501C422.27 19.3301 422.12 19.1801 421.89 19.1801H416.94C416.72 19.1801 416.57 19.3301 416.53 19.5501L415.67 24.5401C415.63 24.7601 415.78 24.9101 416.01 24.9101H420.96C421.18 24.9101 421.33 24.7601 421.37 24.5401Z"
        fill="#00A9E0"
      />
      <path
        d="M436.21 24.56L440.44 0.719986C440.48 0.499986 440.33 0.359985 440.11 0.359985H435.23C435.01 0.359985 434.8 0.429987 434.58 0.579987L429.7 3.68999C429.52 3.82999 429.45 3.97998 429.41 4.19998L428.54 9.28999C428.47 9.57999 428.68 9.64999 428.9 9.50999L433.42 6.72999H433.49L430.31 24.58C430.27 24.8 430.42 24.94 430.64 24.94H435.81C436.03 24.94 436.17 24.8 436.21 24.58V24.56Z"
        fill="#00A9E0"
      />
      <path
        d="M393.61 0.419983C393.61 0.419983 393.51 0.359985 393.44 0.359985H388.55C388.34 0.359985 388.29 0.539987 388.28 0.619987L385.71 15.11H385.62L380.66 0.639984C380.58 0.449984 380.45 0.359985 380.24 0.359985H375.06C374.85 0.359985 374.8 0.539987 374.79 0.619987L370.52 24.72C370.52 24.72 370.52 24.82 370.55 24.87C370.58 24.91 370.64 24.93 370.72 24.93H375.61C375.73 24.93 375.82 24.83 375.85 24.68L378.43 10.19H378.56L383.52 24.67C383.57 24.86 383.7 24.94 383.91 24.94H389.13C389.33 24.94 389.39 24.78 389.4 24.69L393.67 0.589981C393.67 0.589981 393.67 0.489987 393.64 0.439987L393.61 0.419983Z"
        fill="#00A9E0"
      />
      <path
        d="M406.59 0.109985C402.32 0.109985 398.94 1.81998 397.02 5.76998C395.7 8.43998 394.64 15.2 394.64 17.55C394.64 22.57 397.84 25.16 402.68 25.16C406.91 25.16 410.29 23.45 412.25 19.5C413.57 16.83 414.63 10.07 414.63 7.71999C414.63 2.69999 411.43 0.109985 406.59 0.109985ZM403.75 22.4C403.75 22.4 403.72 22.41 403.72 22.4V15.13H399.4L405.5 2.86999C405.5 2.84999 405.54 2.85999 405.54 2.86999V10.15H409.87L403.76 22.4H403.75Z"
        fill="#00A9E0"
      />
    </svg>
  )
}

export default Ankerslogen
